<template>
  <form
    action="/register"
    method="POST"
    class="flex-grow-1 flex-column"
  >
    <div class="text-center tc-800 fs-2">{{ header }}</div>
    <br>

    <template v-if="message">
      <div class="alert alert-warning text-center">{{ message }}</div>
    </template>

    <div class="form-group mb-3">
      <input
        type="text"
        class="form-control"
        name="username"
        :placeholder="usernamePlaceholder"
        required
      >
    </div>

    <!-- Note, we're using mb-2 not mb-3 to group the e-mail sections. -->
    <div class="form-group mb-2">
      <input
        type="email"
        class="form-control"
        name="email"
        :placeholder="emailPlaceholder"
        required
      >
    </div>

    <div class="form-group mb-3">
      <input
        type="email"
        class="form-control"
        name="email_confirm"
        :placeholder="emailConfirmPlaceholder"
        required
      >
    </div>

    <div class="form-group mb-3">
      <div class="row">
        <div class="col-md col-sm-12 mb-2">
          <input
            type="password"
            class="form-control"
            name="password"
            :placeholder="passwordPlaceholder"
            :minlength="minPasswordLength"
            :maxlength="maxPasswordLength"
            required
          >
        </div>
        <div class="col-md col-sm-12">
          <input
            type="password"
            class="form-control"
            name="password_confirm"
            :placeholder="passwordConfirmPlaceholder"
            :minlength="minPasswordLength"
            :maxlength="maxPasswordLength"
            required
          >
          <input type="text" name="crow">
        </div>
      </div>
      <div class="col-sm-12 mt-3 text-center">
        <span class="form-text text-muted">{{ passwordGuidelnes }}</span>
      </div>
    </div>

    <div class="form-group text-center mb-3">
      <h4 class="tc-800">MGW Membership details</h4>
      <hr>
      <p>
        To verify your billing details, we need the e-mail address you used to join MyGrailWatch on <a href="https://patreon.com/mygrailwatch" target="_blank">Patreon</a> or <a href="https://buymeacoffee.com/mygrailwatch" target="_blank">Buy Me a Coffee</a>.
      </p>
      <div class="d-flex">
        <div class="form-check m-auto me-2">
          <input
            id="email-billing-same-input"
            class="form-check-input"
            type="checkbox"
            name="email-billing-same"
            v-model="emailBillingModel"
          >
          <label class="form-check-label" for="email-billing-same-input">
            <span>It's the same as above.</span>
          </label>
        </div>

        <input
          class="form-control flex-grow-1 w-auto"
          type="email"
          placeholder="The e-mail address you used on Patreon or Buy Me a Coffee"
          :disabled="emailBillingModel"
        >
      </div>
    </div>

    <div class="form-group my-3 text-center">
      <div class="row">
        <div class="col" v-html="captchaTag"></div>
      </div>
    </div>

    <div class="form-group text-end">
      <input
        type="submit"
        class="btn me-1"
        :value="i18nButtonRegister">
      <input
        type="reset"
        class="btn"
        :value="i18nButtonReset">
    </div>
  </form>
</template>

<script>
export default {
  props: {
    header: String,
    message: String,
    usernamePlaceholder: String,
    emailPlaceholder: String,
    emailConfirmPlaceholder: String,
    passwordPlaceholder: String,
    passwordConfirmPlaceholder: String,
    minPasswordLength: String,
    maxPasswordLength: String,
    passwordGuidelnes: String,
    i18nButtonRegister: String,
    i18nButtonReset: String,
    captchaTag: String
  },
  data() {
    return {
      emailBillingModel: false
    }
  }
}
</script>
